@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Marcellus:300,400,600,700");

$black: rgba(0,0,0,0);
$icon: #8E8E8E;
$bg:#F5F5F5;
$ash:#535763;
$gray:#696F79;
$body: rgba($black,0.8);
$blacklight: rgba($black,0.12);
$heading: $black;
$error: rgb(239,68,68);
$warning: #f97316;
$primary: #0A5A9A;
$secondary: #2C73EB;
$dark: #22262F;
$success: #16A34A;

body {
  margin: 0;
  font-family: 'Open+Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245 245 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1,
.container h1,
.container h2,
.container h3{
  font-family: 'Marcellus', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
 /*  font-weight: 400; */
}


@tailwind base;
@tailwind components;
@tailwind utilities;


p{
  margin: 0.5em 0;
  line-height: 1.6;
}

div.container{
  max-width: 100%;
}

div.react-tel-input{

  border-radius: 0.5rem;

  .form-control{
    width: 100%;
    height: 3rem;
    background-color: transparent;

    &:focus{
      border-color: $primary;
    }
  }

  .form-control,
  .selected-flag,
  .flag-dropdown{
    border-radius: 0.5rem !important;
    background-color: transparent !important;
  }

  .flag-dropdown,
  .selected-flag{
    border: none;
  }
  .selected-flag{
    padding-left: 1rem;
  }

}

div.country-select{
  padding: 0;

  #rfs-btn{
    border-radius: 0.5rem;
    padding:0.4rem .5rem;
    height: 3rem;
    background-color: rgb(10 90 154 / 0.05);
    border-color: rgba($black , 0.1);
    
    &:focus{
      border-color: $primary;
    }
  }

}

div.react-confirm-alert-overlay{
  background-color: rgba($black , 0.6);
}


.filter-drop .react-select__menu .react-select__option {
  padding: 4px;
}


.filter-drop  .react-select__menu{
  position: relative !important;
  border:none !important;
  box-shadow: none !important;
}


.filter-drop .react-select__input{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}

.filter-drop .react-select__control{
  border:1px solid rgb(229, 231, 235) !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  min-height: 40px;
  min-height: 40px;
  overflow: hidden;
}

.filter-drop .react-select__indicators{
  display: none !important;
}


.filter-drop .react-select__single-value,
.filter-drop .react-select__placeholder{
  font-size: 14px;
}


.filter-drop .react-select__multi-value{
  border-radius: 8px !important;
  overflow: hidden;
  max-width: 80px;
}



.cs-select .cs-select__control{
  min-height: 48px;
  background-color: rgb(10 90 154 / 0.05);
}


.otp-wrp{
  display: grid !important;
}
.otp-input{
  width: 60px !important;
  height: 60px;
}


@media screen  and ( min-width: 1200px ) and ( max-height: 650px ) {

.login-form{

  font-size: 14px;

  .text-2xl{
    font-size: 24px;
  }

  button,
  input{
    padding: 12px 12px;
  }

  .mb-8{
    margin-bottom: 12px;
  }
}

}
@media screen  and ( max-width: 767px ) {

  div.container{
    max-width: 90%;
  }

}